import { ready } from 'brei-project-utils';

import { forms } from './modules/forms';
import { media } from './modules/media';
import { table } from './modules/table';

const level = {

	elem: {
	},

	init() {

		forms.init();
		media.init();
		table.init();		

	}

};

ready(function () {
	level.init();
});
